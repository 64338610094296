import { GenericModel } from '@shared/models/generic.model';
import {
    IArea,
    IBuildingAreaDto,
    IBuilding,
    IBuildingDto,
    IBuildingGroup,
    IBuildingGroupDto,
    IBuildingCallTypeDto,
    IBuildingCallType,
    IBuildingLiftArea,
    IBuildingLiftAreaDto,
} from '@shared/interfaces';
import { GenericBuildingModel } from '@shared/models/generic-building.model';

export class BuildingModel
    extends GenericBuildingModel<IBuilding, IBuildingDto>
    implements IBuilding
{
    _id: string;
    buildingName: string;
    comment: string;
    areas: BuildingAreaModel[];
    groups: BuildingGroupModel[];
    callTypes: BuildingCallTypeModel[];
    liftAreas: BuildingLiftAreaModel[];

    fullBuildingId: string;

    constructor(buildingDto?: IBuildingDto) {
        super(
            buildingDto,
            BuildingModel,
            buildingDto?.buildingId,
            buildingDto?.isAcs,
            undefined,
            buildingDto?.groupId,
        );

        this._id = buildingDto?._id || '';
        this.buildingName = buildingDto?.buildingName || '';
        this.comment = buildingDto?.comment || '';
        this.groups = buildingDto?.groups.map(group => new BuildingGroupModel(group)) || [];
        this.callTypes =
            buildingDto?.callTypes.map(callType => new BuildingCallTypeModel(callType)) || [];
        this.liftAreas =
            buildingDto?.liftAreas.map(liftArea => new BuildingLiftAreaModel(liftArea)) || [];
        this.areas =
            buildingDto?.areas
                .map(area => new BuildingAreaModel(area))
                .sort(this.sortByHighestFloor) || [];

        this.fullBuildingId = this.getFullBuildingId();
    }

    dataTransferObject(): IBuildingDto {
        const buildingDto: IBuildingDto = this.getOriginalData();

        buildingDto.buildingId = this.buildingId;
        buildingDto.buildingName = this.buildingName;
        buildingDto.comment = this.comment;
        buildingDto.isAcs = this.isAcs;
        buildingDto.areas = this.areas.map(area => area.dataTransferObject());
        buildingDto.groups = this.groups.map(group => group.dataTransferObject());

        if (this.groupId) buildingDto.groupId = this.groupId;

        return buildingDto;
    }

    private sortByHighestFloor(a: BuildingAreaModel, b: BuildingAreaModel): number {
        // Convert to uppercase for case-insensitive comparison
        const aUpper: string = a.shortName.toUpperCase();
        const bUpper: string = b.shortName.toUpperCase();

        // Extract numeric part and alphabetic part
        const aNum: number = parseInt(aUpper);
        const bNum: number = parseInt(bUpper);
        const aAlpha: string = aUpper.replace(/[0-9]/g, '');
        const bAlpha: string = bUpper.replace(/[0-9]/g, '');

        // Compare numbers first
        if (aNum < bNum) return 1;
        if (aNum > bNum) return -1;

        // If numbers are equal, compare alphabetic parts
        if (aAlpha < bAlpha) return 1;
        if (aAlpha > bAlpha) return -1;

        return 0;
    }
}

export class BuildingAreaModel extends GenericModel<IArea, IBuildingAreaDto> implements IArea {
    areaId: string;
    shortName: string;
    terminal: string;
    zones: string[];

    constructor(areaDto: IBuildingAreaDto | null = null) {
        super(areaDto, BuildingAreaModel);

        this.areaId = areaDto?.areaId || '';
        this.shortName = areaDto?.shortName || '';
        this.terminal = areaDto?.terminal?.toString() || '';
        this.zones = areaDto?.zones?.map(String) || [];
    }

    dataTransferObject(): IBuildingAreaDto {
        const buildingAreaDto: IBuildingAreaDto = this.getOriginalData();

        buildingAreaDto.areaId = this.areaId;
        buildingAreaDto.shortName = this.shortName;
        buildingAreaDto.terminal = this.terminal;
        buildingAreaDto.zones = this.zones;

        return buildingAreaDto;
    }
}

export class BuildingGroupModel
    extends GenericModel<IBuildingGroup, IBuildingGroupDto>
    implements IBuildingGroup
{
    terminals: string[];

    constructor(areaDto: IBuildingGroupDto | null = null) {
        super(areaDto, BuildingGroupModel);

        this.terminals = areaDto?.terminals || [];
    }

    dataTransferObject(): IBuildingGroupDto {
        const buildingGroupDto: IBuildingGroupDto = this.getOriginalData();

        buildingGroupDto.terminals = this.terminals;

        return buildingGroupDto;
    }
}

export class BuildingCallTypeModel
    extends GenericModel<IBuildingCallType, IBuildingCallTypeDto>
    implements IBuildingCallType
{
    action_id: string;
    action_type: string;
    direct_allowed: boolean;
    enabled: boolean;
    group_call_allowed: boolean;
    key: number;
    name: string;
    preferred_allowed: boolean;

    constructor(callTypeDto: IBuildingCallTypeDto | null = null) {
        super(callTypeDto, BuildingCallTypeModel);

        this.action_id = callTypeDto?.action_id.toString() || '';
        this.action_type = callTypeDto?.action_type || '';
        this.direct_allowed = callTypeDto?.direct_allowed || false;
        this.enabled = callTypeDto?.enabled || false;
        this.group_call_allowed = callTypeDto?.group_call_allowed || false;
        this.key = callTypeDto?.key || -1;
        this.name = callTypeDto?.name || '';
        this.preferred_allowed = callTypeDto?.preferred_allowed || false;
    }

    dataTransferObject(): IBuildingCallTypeDto {
        const buildingCallTypeDto: IBuildingCallTypeDto = this.getOriginalData();

        return buildingCallTypeDto;
    }
}

export class BuildingLiftAreaModel
    extends GenericModel<IBuildingLiftArea, IBuildingLiftAreaDto>
    implements IBuildingLiftArea
{
    group: string;
    lift: string;
    liftArea: string;
    liftName: string;

    constructor(liftAreaDto: IBuildingLiftAreaDto | null = null) {
        super(liftAreaDto, BuildingLiftAreaModel);

        this.group = liftAreaDto?.group || '';
        this.lift = liftAreaDto?.lift || '';
        this.liftArea = liftAreaDto?.liftArea || '';
        this.liftName = liftAreaDto?.liftName || '';
    }

    dataTransferObject(): IBuildingLiftAreaDto {
        const buildingLiftAreaDto: IBuildingLiftAreaDto = this.getOriginalData();

        return buildingLiftAreaDto;
    }
}
