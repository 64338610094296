import { ApplicationRef, Component, inject, signal, Signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserStoreService } from '@shared/services/user-store.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { AppService } from '@shared/services/app.service';
import { TranslateModule } from '@ngx-translate/core';
import { ThemeService } from '@shared/services/theme.service';
import { IconComponent, TIcon } from '@shared/components/icon/icon.component';
import { ClickPropagationDirective, SecondaryLayerColor } from '@shared/directives';
import { CustomDialogComponent } from '@shared/components/custom-dialog';
import {
    CustomDialogActionsDirective,
    CustomDialogContentDirective,
    CustomDialogHeaderRightDirective,
} from '@shared/components/custom-dialog/directives';
import { CustomCardComponent } from '@shared/components/custom-card';
import { ItemHighlightComponent } from '@shared/components/item-highlight';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { UserDataModel } from '@shared/models';
import { MatDialogRef } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CustomButtonComponent } from '@shared/components/custom-button';
import { AppStylesService } from '@shared/services/app-styles.service';
import { IStyleVariable } from '@shared/interfaces';
import { toSignal } from '@angular/core/rxjs-interop';
import { UserManagementHttpService } from '@core/http/user-management-http.service';
import { RoleDescriptionPipe } from '@shared/components/user-profile-dialog/role-description.pipe';

@Component({
    standalone: true,
    selector: 'app-user-profile-dialog',
    template: `
        <custom-dialog label="" minWidth="300">
            <ng-template customDialogHeaderRight>
                <div class="flex-row align-start-center item-margin-right">
                    <icon [name]="iconTheme()" size="28" class="item-margin-right" />
                    <mat-slide-toggle
                        color="primary"
                        (click)="changeTheme()"
                        [checked]="iconTheme() === 'dark_mode'" />
                </div>
            </ng-template>

            <ng-template customDialogContent>
                @if (user(); as user) {
                    <custom-card class="flex-column align-center-center" secondary-layer-color>
                        <icon name="user" size="80" [customColor]="styles().textColorSoft" />
                        <div class="flex-column align-center-center">
                            <div class="text-color-soft">{{ user.email }}</div>

                            @if (user.role.admin) {
                                <div class="flex-row align-start-center item-margin-top">
                                    <item-highlight primary>
                                        {{ 'acm_global_admin' | translate }}
                                    </item-highlight>
                                    <icon
                                        class="item-margin-left-half"
                                        name="help"
                                        customColor="white"
                                        size="16"
                                        backButtonBgPadding="2"
                                        backButtonBgColor="var(--primary-color)"
                                        [tooltip]="'globalAdmin' | roleDescription: roles()" />
                                </div>
                            }

                            @if (user.role.user) {
                                <div class="flex-row align-start-center item-margin-top">
                                    <item-highlight primary>
                                        {{ 'acm_kone_technical' | translate }}
                                    </item-highlight>
                                    <icon
                                        class="item-margin-left-half"
                                        name="help"
                                        customColor="white"
                                        size="16"
                                        backButtonBgPadding="2"
                                        backButtonBgColor="var(--primary-color)"
                                        [tooltip]="'technical' | roleDescription: roles()" />
                                </div>
                            }

                            @if (user.role.fm) {
                                <div class="flex-row align-start-center item-margin-top">
                                    <item-highlight primary>
                                        {{ 'acm_facility_manager' | translate }}
                                    </item-highlight>
                                    <icon
                                        class="item-margin-left-half"
                                        name="help"
                                        customColor="white"
                                        size="16"
                                        backButtonBgPadding="2"
                                        backButtonBgColor="var(--primary-color)"
                                        [tooltip]="'facilityManager' | roleDescription: roles()" />
                                </div>
                            }
                            @if (user.role.readOnly) {
                                <div class="flex-row align-start-center item-margin-top">
                                    <item-highlight primary>
                                        {{ 'acm_viewer' | translate }}
                                    </item-highlight>
                                    <icon
                                        class="item-margin-left-half"
                                        name="help"
                                        customColor="white"
                                        size="16"
                                        backButtonBgPadding="2"
                                        backButtonBgColor="var(--primary-color)"
                                        [tooltip]="'viewer' | roleDescription: roles()" />
                                </div>
                            }
                        </div>
                    </custom-card>
                }
            </ng-template>

            <ng-template customDialogActions>
                <custom-button
                    (click)="changePassword()"
                    [disabled]="loading"
                    class="item-margin-bottom"
                    icon="key"
                    label="acm_change_password"
                    type="stroked"
                    color="primary"
                    fill />
                <custom-button
                    (click)="logout()"
                    [disabled]="loading"
                    [loadingInside]="loading"
                    icon="logout"
                    label="acm_logout"
                    type="flat"
                    color="delete"
                    fill />
            </ng-template>
        </custom-dialog>
    `,
    imports: [
        CommonModule,
        ClickPropagationDirective,
        MatMenuModule,
        MatIconModule,
        TranslateModule,
        IconComponent,
        CustomDialogComponent,
        CustomDialogActionsDirective,
        CustomDialogContentDirective,
        CustomCardComponent,
        SecondaryLayerColor,
        ItemHighlightComponent,
        CustomDialogHeaderRightDirective,
        MatSlideToggleModule,
        MatProgressSpinnerModule,
        CustomButtonComponent,
        RoleDescriptionPipe,
    ],
})
export class UserProfileDialogComponent {
    private userStoreService: UserStoreService = inject(UserStoreService);
    private appService: AppService = inject(AppService);
    private themeService: ThemeService = inject(ThemeService);
    private applicationRef: ApplicationRef = inject(ApplicationRef);
    private appStylesService: AppStylesService = inject(AppStylesService);
    private userManagementHttp = inject(UserManagementHttpService);

    loading: WritableSignal<boolean> = signal<boolean>(false);
    user: Signal<UserDataModel> = this.userStoreService.user;
    iconTheme: Signal<TIcon> = this.themeService.iconTheme;
    styles: Signal<IStyleVariable> = this.appStylesService.styles;

    roles = toSignal(this.userManagementHttp.getRoles(), { initialValue: [] });

    constructor(private dialogRef: MatDialogRef<UserProfileDialogComponent>) {}

    changeTheme(): void {
        this.themeService.trigger();
        this.appStylesService.fnInit(); // refresh variables
        this.applicationRef.tick(); // refresh application
    }

    changePassword(): void {
        this.dialogRef.close();
        this.appService.changePassword();
    }

    logout(): void {
        if (this.loading()) return;

        this.loading.set(true);

        setTimeout(() => {
            this.dialogRef.close();
            this.appService.logout();
        }, 800);
    }
}
