@if (isTablet()) {
    <img class="logo" loading="lazy" src="assets/images/kone-logo.png" />
}

<div class="flex-column flex">
    <!--    <div class="page-sub-title">{{ router.url | headerTitlePipe: titleMap : 'subtitle' }}</div>-->
    <div class="page-title">{{ environment.name }}</div>
</div>

<div class="align-center-center flex-row flex">
    <div class="middle-text">{{ middleText$ | async }}</div>
</div>

<div class="align-end-center flex">
    <frontlines-selector />
    <sites-selector />
    <date-filter-selector />
    <user-picture-profile [dialogComponent]="UserProfileDialogComponent" />
</div>
