import { inject, Pipe, PipeTransform } from '@angular/core';
import { IStyleVariable, TBgColor } from '@shared/interfaces';
import { AppStylesService } from '@shared/services/app-styles.service';

@Pipe({
    standalone: true,
    name: 'bgColorVariable',
})
export class BgColorVariablePipe implements PipeTransform {
    private appConstantsService: AppStylesService = inject(AppStylesService);

    transform(color: TBgColor): string {
        let key: keyof IStyleVariable;

        switch (color) {
            case 'primary':
                key = 'primaryLayerColor';
                break;
            case 'secondary':
                key = 'secondaryLayerColor';
                break;
            case 'tertiary':
                key = 'tertiaryLayerColor';
                break;
            case 'fourth':
                key = 'fourthLayerColor';
                break;
            case 'fifth':
                key = 'fifthLayerColor';
                break;
            case 'bgPrimary':
                key = 'primaryBackground';
                break;
        }

        return key ? this.appConstantsService.convertCamelToCssVar(key) : '';
    }
}
