import {
    ISiteAddress,
    ISiteAddressDto,
    ISiteContact,
    ISiteContactDto,
    ISite,
    ISiteDto,
    ISiteBuildingDto,
    TSiteStatus,
    ISiteBuilding,
    ISiteCallTypeDto,
    ISiteCallType,
    TSite,
    ISiteCallLimits,
    ISiteCallLimitsDto,
    ISiteGeoData,
    ISiteGeoDataDto,
} from '@shared/interfaces';
import { GenericModel } from '@shared/models/generic.model';
import { GenericBuildingModel } from '@shared/models/generic-building.model';
import { UserPhoneNumberModel } from '@shared/models/user-phone-number.model';
import { UserDataAccessModel } from '@shared/models/user-data-access.model';

export class SiteModel extends GenericModel<ISite, ISiteDto> implements ISite {
    _id: string;
    apf: string;
    buildingList: SiteBuildingModel[];
    contactList: SiteContactModel[];
    address: AddressModel;
    callLimits: SiteCallLimitsModel;
    geoData: SiteGeoDataModel;
    koneId: string;
    languageCode: string;
    name: string;
    type: string;
    timeZone: string;
    description: string;
    companyCode: string;
    siteType: TSite;
    tier: string;
    status: TSiteStatus;

    constructor(siteDto: ISiteDto | null = null) {
        super(siteDto, SiteModel);

        this._id = siteDto?._id || '';
        this.apf = siteDto?.apf || '';
        this.koneId = !siteDto?.koneId || siteDto?.koneId == '???' ? 'n/a' : siteDto.koneId;
        this.languageCode = siteDto?.languageCode || 'en';
        this.name = siteDto?.name || '';
        this.description = siteDto?.description || '';
        this.companyCode = siteDto?.sapCompanycode || '';
        this.type = siteDto?.type || 'site';
        this.timeZone = siteDto?.timeZone || 'Europe/Helsinki';
        this.status =
            siteDto?.status === 'active' || siteDto?.status === 'inactive'
                ? siteDto?.status
                : 'inactive';
        this.tier = siteDto?.tier || 'basic';
        this.siteType = siteDto?.siteType || 'DEMO';
        this.address = new AddressModel(siteDto?.address, this.companyCode);
        this.callLimits = new SiteCallLimitsModel(siteDto?.callLimits);
        this.geoData = new SiteGeoDataModel(siteDto?.geoData ? siteDto?.geoData[0] : undefined);
        this.buildingList = siteDto?.buildingList?.map(b => new SiteBuildingModel(b)) || [];
        this.contactList = siteDto?.contactList?.map(cl => new SiteContactModel(cl)) || [];
    }

    dataTransferObject(): ISiteDto {
        const siteDto: ISiteDto = this.getOriginalData();

        siteDto.apf = this.apf;
        siteDto.koneId = this.koneId;
        siteDto.languageCode = this.languageCode;
        siteDto.name = this.name;
        siteDto.type = this.type;
        siteDto.description = this.description;
        siteDto.timeZone = this.timeZone;
        siteDto.status = this.status;
        siteDto.siteType = this.siteType;
        siteDto.tier = this.tier;
        siteDto.address = this.address.dataTransferObject();
        siteDto.callLimits = this.callLimits.dataTransferObject();
        siteDto.geoData = [this.geoData.dataTransferObject()];
        siteDto.buildingList = this.buildingList.map(b => b.dataTransferObject());
        siteDto.contactList = this.contactList.map(cl => cl.dataTransferObject());

        if (this.companyCode) siteDto.sapCompanycode = this.companyCode;

        return siteDto;
    }
}

export class AddressModel
    extends GenericModel<ISiteAddress, ISiteAddressDto>
    implements ISiteAddress
{
    city: string;
    country: string;
    street: string;

    private readonly companyCode: string;

    constructor(address: ISiteAddressDto | undefined, companyCode: string) {
        super(address, AddressModel);

        this.city = address?.city || '';
        this.country = address?.country || '';
        this.street = address?.street || '';
        this.companyCode = companyCode;
    }

    get fullCountry(): string {
        return this.country + (this.companyCode ? ' (' + this.companyCode + ')' : '');
    }

    dataTransferObject(): ISiteAddressDto {
        return {
            city: this.city,
            country: this.country,
            street: this.street,
        };
    }
}

export class SiteBuildingModel
    extends GenericBuildingModel<ISiteBuilding, ISiteBuildingDto>
    implements ISiteBuilding
{
    id: string;
    comment: string;
    name: string;
    terminal: string;
    callTypeId: number;
    terminalId: number;
    callTypes: SiteCallTypeModel[];
    kecmAllLifts: number[];
    allowedLifts: number[];
    fullBuildingId: string;

    constructor(buildingDto?: ISiteBuildingDto) {
        super(
            buildingDto,
            SiteBuildingModel,
            buildingDto?.id,
            buildingDto?.isAcs,
            buildingDto?.sfsId,
            buildingDto?.groupId,
        );

        this.id = buildingDto?.id || '';
        this.comment = buildingDto?.comment || '';
        this.name = buildingDto?.name || '';
        this.terminal = buildingDto?.terminal || '';
        this.kecmAllLifts = buildingDto?.kecmAllLifts || [];
        this.allowedLifts = buildingDto?.allowedLifts || [];
        this.callTypeId = buildingDto?.callTypeId ?? -1;
        this.terminalId = buildingDto?.terminalId ?? 0;
        this.callTypes = buildingDto?.callTypes.map(ct => new SiteCallTypeModel(ct)) || [];

        if (buildingDto?.groupId) this.groupId = buildingDto.groupId;

        this.fullBuildingId = this.getFullBuildingId();
    }

    dataTransferObject(): ISiteBuildingDto {
        const siteBuildingDto: ISiteBuildingDto = this.getOriginalData();

        siteBuildingDto.comment = this.comment;
        siteBuildingDto.terminal = this.terminal;
        siteBuildingDto.isAcs = this.isAcs;
        siteBuildingDto.sfsId = this.sfsId;
        siteBuildingDto.allowedLifts = this.allowedLifts;
        siteBuildingDto.callTypeId = this.callTypeId;
        siteBuildingDto.terminalId = this.terminalId;
        siteBuildingDto.groupId = this.groupId;
        siteBuildingDto.name = this.name;
        siteBuildingDto.callTypes = this.callTypes.map(ct => ct.dataTransferObject());

        if (this.groupId) siteBuildingDto.groupId = this.groupId;

        return siteBuildingDto;
    }
}

export class SiteCallTypeModel
    extends GenericModel<ISiteCallType, ISiteCallTypeDto>
    implements ISiteCallType
{
    callTypeId: number;
    code: string;
    desc: string;
    origin?: string;

    constructor(
        callTypeDto: ISiteCallTypeDto | null = null,
        callType: ISiteCallType | null = null,
    ) {
        super(callTypeDto, SiteCallTypeModel);

        this.callTypeId = Number(callType?.callTypeId || callTypeDto?.callTypeId || NaN);
        this.code = callType?.code || callTypeDto?.code || '';
        this.desc = callType?.desc || callTypeDto?.desc || '';
        this.origin = callType?.origin || callTypeDto?.origin || '';

        if (callType?.origin || callTypeDto?.origin)
            this.origin = callType?.origin || callTypeDto?.origin;
    }

    dataTransferObject(): ISiteCallTypeDto {
        const siteCallTypeDto: ISiteCallTypeDto = this.getOriginalData();

        siteCallTypeDto.code = this.code;
        siteCallTypeDto.desc = this.desc;

        if (this.origin) siteCallTypeDto.origin = this.origin;

        return siteCallTypeDto;
    }
}

export class SiteCallLimitsModel
    extends GenericModel<ISiteCallLimits, ISiteCallLimitsDto>
    implements ISiteCallLimits
{
    amount: number;
    delayMinutes: number;
    enabled: boolean;
    periodHours: number;

    constructor(callLimit?: ISiteCallLimitsDto) {
        super(callLimit, SiteCallLimitsModel);

        this.enabled = callLimit?.enabled ?? false;
        this.delayMinutes = callLimit?.delayMinutes ?? 0;
        this.periodHours = callLimit?.periodHours ?? 0;
        this.amount = callLimit?.amount ?? 0;
    }

    dataTransferObject(): ISiteCallLimitsDto {
        return {
            enabled: this.enabled,
            delayMinutes: this.delayMinutes,
            periodHours: this.periodHours,
            amount: this.amount,
        };
    }
}

export class SiteGeoDataModel
    extends GenericModel<ISiteGeoData, ISiteGeoDataDto>
    implements ISiteGeoData
{
    comment: string;
    geoFencingEnabled: boolean;
    lat: number;
    long: number;
    radius: number;

    constructor(geo?: ISiteGeoDataDto) {
        super(geo, SiteGeoDataModel);

        this.geoFencingEnabled = geo?.geoFencingEnabled ?? false;
        this.lat = geo?.lat ?? 0;
        this.long = geo?.long ?? 0;
        this.radius = geo?.radius ?? 0;
        this.comment = geo?.comment ?? '';
    }

    dataTransferObject(): ISiteGeoDataDto {
        return {
            comment: this.comment,
            geoFencingEnabled: this.geoFencingEnabled,
            long: this.long,
            radius: this.radius,
            lat: this.lat,
        };
    }
}

export class SiteContactModel
    extends GenericModel<ISiteContact, ISiteContactDto>
    implements ISiteContact
{
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    country: string;
    language: string;
    showEmail?: boolean;
    phoneNumber: UserPhoneNumberModel;
    phone: UserPhoneNumberModel;
    dataAccess: UserDataAccessModel;

    constructor(contact: ISiteContactDto | null = null) {
        super(contact, SiteContactModel);

        this.id = contact?.id || '';
        this.email = contact?.email || '';
        this.firstName = contact?.firstName || '';
        this.lastName = contact?.lastName || '';
        this.country = contact?.country || '';
        this.language = contact?.language || '';
        this.showEmail = contact?.showEmail || false;
        this.phoneNumber = new UserPhoneNumberModel(contact?.phone);
        this.phone = new UserPhoneNumberModel(contact?.phone);
        this.dataAccess = new UserDataAccessModel(contact?.dataAccess);
    }

    get fullNumberFormatted(): string {
        return this.phone.fullNumberFormatted;
    }

    dataTransferObject(): ISiteContactDto {
        const contactListDto: ISiteContactDto = this.getOriginalData();

        contactListDto.email = this.email;
        contactListDto.showEmail = this.showEmail;
        contactListDto.country = this.country;
        contactListDto.language = this.language;
        contactListDto.dataAccess = this.dataAccess.dataTransferObject();

        if (this.firstName) contactListDto.firstName = this.firstName;
        if (this.lastName) contactListDto.lastName = this.lastName;
        if (this.phone.fullNumber) {
            contactListDto.phone = this.phone.dataTransferObject();
            contactListDto.phoneNumber = this.phone.fullNumber;
        } else {
            delete contactListDto.phoneNumber;
        }

        return contactListDto;
    }
}
