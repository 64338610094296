@if (menuState()) {
    <div class="image-container">
        <div class="image align-start-center">
            <img loading="lazy" src="assets/images/kone-logo.png" />
        </div>
    </div>

    @for (item of menu; track item; let last = $last) {
        <a
            class="item align-center-center"
            [class.border-bottom]="last"
            [class.disabled]="item.disabled"
            [class.active]="!(loading$ | async) && (item | menuActive: router.url)"
            [class.hover]="isTablet() ? false : item.hover"
            (mouseover)="isTablet() ? null : (item.hover = true)"
            (mouseleave)="isTablet() ? null : (item.hover = false)"
            [routerLink]="item.url"
            (click)="isTablet() ? triggerMenu() : null">
            <div
                class="highlight align-center-center"
                [class.flex-column]="!isTablet()"
                [class.flex-row]="isTablet()"
                [class.tablet]="isTablet()">
                <icon [name]="item.icon" size="30" customColor="rgba(255,255,255,.9)" />
                <div class="text" long-text-ticker>
                    {{ item.tKey | translate }}
                </div>
            </div>
        </a>
    }

    <div class="flex"></div>

    <div class="flex-column flex align-end-center item-margin-bottom">
        <div class="footer-text font-size-sm text-capitalize">
            {{ 'acm_terms_conditions' | translate }}
        </div>
        <div class="footer-text font-size-sm text-capitalize item-margin-bottom-half">
            {{ 'acm_data_privacy' | translate }}
        </div>
        <div class="footer-text font-size-sm text-capitalize item-margin-bottom-half">
            {{ 'acm_version' | translate }} : {{ version }}
        </div>
        <div class="text-color">&copy; KONE {{ year }}</div>
    </div>
}

@if (isTablet()) {
    <div class="trigger-icon-outside" [class.is-open]="menuState()">
        <icon
            name="first_page"
            customColor="var(--color-text)"
            [rotate]="!menuState()"
            rotateDeg="-180"
            backButtonBorderRadius="var(--border-radius-btn)"
            backButtonBgColor="var(--side-menu-color)"
            (click)="triggerMenu()" />
    </div>

    <div class="backdrop" (click)="triggerMenu()"></div>
}
